<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateDisplayFormat"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        outlined
        hide-details
        dense
        v-bind="attrs"
        v-on="on" />
    </template>
    <v-date-picker
      v-model="date"
      no-title
      scrollable>
      <v-btn
        text
        color="primary"
        @click="clearValue()"
      >
        ล้างค่า
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="menu = false"
      >
        ยกเลิก
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="$refs.menu.save(date)"
      >
        ยืนยัน
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
  export default {
    props: {
      value: {
        type: String,
        default: null
      },
      label: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        menu: false
      }
    },
    computed: {
      date: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      },
      dateDisplayFormat () {
        return this.date ? this.$dayjs(this.date).format('DD MMM YYYY') : 'ไม่กำหนด'
      }
    },
    methods: {
      clearValue () {
        this.date = null
      }
    }
  }
</script>
