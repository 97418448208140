<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-top-transition">
    <v-card>
      <v-toolbar
        dark
        color="primary">
        <v-toolbar-title>สมาชิก</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            icon
            dark
            @click="closeModal()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container
        class="products-list-page"
        fluid>
        <member-header
          :filter="filter"
          :edit-mode="false"
          @filter-clicked="filterClicked($event)" />
        <v-row class="products-row">
          <v-col cols="12">
            <member-list
              v-model="query"
              :items="members"
              :loading="fetchingMembers"
              :edit-mode="false"
              @edit="editClicked($event)"
              @return-members="recieveMembers($event)"
              @change-type="tryToChangeType" />
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center">
            <div>
              <v-btn
                color="secondary"
                @click="submit(false)">
                เลือก
              </v-btn>
              <v-btn
                color="secondary"
                class="ml-4"
                @click="submit(true)">
                เลือกทั้งหมด
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import MemberProvider from '@/resources/MemberProvider'
import MemberHeader from './MembersHeader.vue'
import MemberList from './MembersList.vue'

const MemberService = new MemberProvider()

export default {
  components: {
    MemberHeader,
    MemberList
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      fetchingMembers: false,
      members: [],
      memberSelected: [],
      filter: {
        type: '',
        birthMonth: '',
        createdIn: '',
        spendingMinPrice: null,
        spendingMaxPrice: null,
        search: ''
      },
      query: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
        pages: 1,
        sortBy: ['createdAt'],
        sortDesc: [true]
      }
    }
  },
  watch: {
    query: {
      handler () {
        this.getMembers()
      },
      deep: true
    }
  },
  async mounted () {
    this.getMembers()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading',
      setMember: 'Member/setMember'
    }),
    isDigits (value) {
      // eslint-disable-next-line
      return value == +value
    },
    telFormatter (_tel) {
      let tel = _tel
      if (this.isDigits(tel) && tel[0] !== '+' && tel.length === 10) {
        tel = tel.slice(1, 10)
      }

      return tel
    },
    async getMembers () {
      try {
        this.fetchingMembers = true
        const search = this.telFormatter(this.filter.search)
        const { data } = await MemberService.getMembersByPaginate({ ...this.query, ...this.filter, search })
        this.members = data.results
        this.query.total = data.total
        this.query.pages = data.pages
      } catch (error) {
        console.error('error', error)
        this.setSnackbar({
          value: true,
          message: `[GETTING MEMBER INFO]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.fetchingMembers = false
      }
    },
    filterClicked (filter) {
      this.filter = filter
      this.getMembers()
    },
    editClicked (member) {
      this.setMember(member)
      this.$router.push({ name: 'EditMember', params: { id: member.id } })
    },
    async tryToChangeType (member, type) {
       this.setModal({
        value: true,
        title: 'Change member type',
        message: 'Do you want to change type of member?',
        confirmText: 'Confirm',
        confirmType: 'success',
        cancelType: '',
        cancelText: 'Cancel',
        onConfirm: () => this.changeTypeMember(member, type)
      })
    },
    async changeTypeMember (member, type) {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'UPDATING MEMBER...'
        })

        const { data } = await MemberService.updateMember(member.id, { ...member, type })
        const foundMember = this.members.findIndex((each) => each.id === data.id)

        if (foundMember !== -1) {
          this.members.splice(foundMember, 1, data)
        }
      } catch (error) {
        console.error('error', error)
        this.setSnackbar({
          value: true,
          message: `[UPDATE MEMBER ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
        this.setSnackbar({
          value: true,
          message: `${member.firstName || 'สมาชิก'} ถูกเปลี่ยนเป็น ${type.toUpperCase()} แล้ว`,
          type: 'success'
        })
      }
    },
    closeModal () {
      this.$emit('close-modal')
    },
    recieveMembers (members) {
      this.memberSelected = members
    },
    async submit (all) {
      let members = this.memberSelected

      if (all) {
        members = await this.getAllMembersWithoutPaginate()
      }

      this.$emit('input', members)
    },
    async getAllMembersWithoutPaginate () {
      this.fetchingMembers = true
      let members = []
      try {
        const { data } = await MemberService.getMembersByPaginate({
          ...this.query,
          ...this.filter,
          itemsPerPage: 9_999_999
        })
        members = data.results
      } catch (error) {
        console.error('error', error)
        this.setSnackbar({
          value: true,
          message: `[GETTING MEMBER INFO]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.fetchingMembers = false
      }

      return members
    }
  }
}
</script>
